import { Button, Descriptions, Modal, Timeline } from 'antd'
import Table, { ColumnsType } from 'antd/lib/table'
import { useEffect, useState } from 'react'

import {
  approvalControllerGetApproval,
  approvalControllerUserApprove,
  approvalControllerUserReject,
  currentUserProfile
} from 'apiClient/services/devops'
import {
  EntityApproval,
  EntityApprovalNode,
  EntityApprovalNodeStatus,
  EntityApprovalStatus,
  EntityApprovalTask,
} from 'apiClient/services/devops/interface'
import { toLocal } from 'pages/Devops/Maintenance/Detail'

const colorMap = {
  PENDING: 'orange',
  APPROVED: 'green',
  REJECTED: 'red',
  CANCELED: 'grey'
} as const

export default function ApprovalTable({ id }: { id: number }) {
  const [currUserEmail, setCurrUserEmail] = useState<string>()
  const [reload, setReload] = useState(false)
  const [approval, setApproval] =
    useState<EntityApproval>()
  useEffect(() => {
    async function selfProfile() {
      try {
        const data = (await currentUserProfile()).data
        if (data.email) {
          setCurrUserEmail(data.email)
        }
        const dataApproval = (await approvalControllerGetApproval(id)).data
          .approval
        setApproval(dataApproval)
      } catch (e) {
        console.error(e)
      }
    }
    selfProfile()
  }, [reload])

  const columnsApprovalTask: ColumnsType<EntityApprovalTask> =
    [
      {
        title: 'Email',
        render: (_: any, record) => {
          return record.user_email
        }
      },
      {
        title: 'Status',
        render: (_: any, record) => {
          return record.status
        }
      },
      {
        title: 'Start Time',
        render: (_: any, record) => {
          return (
            <>
              {toLocal(record.start_at)} ~ {toLocal(record.end_at)}
            </>
          )
        }
      }
    ]

  const canApprove = () => {
    if (
      approval?.status ===
      EntityApprovalStatus.PENDING
    ) {
      let emails = getFristPendingNode(approval?.nodes || [])?.tasks?.map(
        (v) => {
          return v.user_email
        }
      )
      if (currUserEmail && emails?.includes(currUserEmail)) {
        return true
      }
    } else {
      return false
    }
  }

  const handleApproval = async () => {
    Modal.info({
      title: 'Info',
      closable: true,
      content: (
        <div>
          <p>
            Are you sure to <b>APPROVE</b>?
          </p>
        </div>
      ),
      okText: 'Confirm',
      async onOk() {
        try {
          await approvalControllerUserApprove(id)
          Modal.success({
            title: 'info',
            content: (
              <div>
                <p>Successfully Approve</p>
              </div>
            ),
            onOk() {}
          })
          setReload((pre) => !pre)
        } catch (error) {
          Modal.error({
            title: error.response.data.errcode,
            content: error.response.data.errmsg
          })
          setReload((pre) => !pre)
        }
      }
    })
  }

  const handleReject = async () => {
    Modal.info({
      title: 'Info',
      closable: true,
      content: (
        <div>
          <p>
            Are you sure to <b>Reject</b>?
          </p>
        </div>
      ),
      okText: 'Confirm',
      async onOk() {
        try {
          await approvalControllerUserReject(id)
          Modal.success({
            title: 'info',
            content: (
              <div>
                <p>Successfully Reject</p>
              </div>
            ),
            onOk() {}
          })
          setReload((pre) => !pre)
        } catch (error) {
          Modal.error({
            title: error.response.data.errcode,
            content: error.response.data.errmsg
          })
          setReload((pre) => !pre)
        }
      }
    })
  }

  return (
    <div>
      <div className="flex-container">
        <Button
          type="primary"
          disabled={!canApprove()}
          onClick={handleApproval}
        >
          Approve
        </Button>
        &nbsp;
        <Button type="primary" disabled={!canApprove()} onClick={handleReject}>
          Reject
        </Button>
      </div>
      <br></br>
      <Descriptions>
        <Descriptions.Item label="Status" span={1}>
          {approval?.status}
        </Descriptions.Item>
        <Descriptions.Item label="Start Time" span={1}>
          {toLocal(approval?.start_at)}
        </Descriptions.Item>
        <Descriptions.Item label="End Time" span={1}>
          {toLocal(approval?.end_at)}
        </Descriptions.Item>
      </Descriptions>
      <Timeline>
        {approval?.nodes?.map((n, i) => {
          return (
            <Timeline.Item color={colorMap[n.status!]}>
              {' '}
              <div>
                <b style={{ color: colorMap[n.status!] }}>{n.status}</b>
                <Table<EntityApprovalTask>
                  columns={columnsApprovalTask}
                  rowKey="id"
                  dataSource={n.tasks}
                  size="small"
                  pagination={false}
                  showHeader={false}
                ></Table>
              </div>
            </Timeline.Item>
          )
        })}
      </Timeline>
    </div>
  )
}

const getFristPendingNode = (nodes: EntityApprovalNode[]) => {
  for (let node of nodes) {
    if (node.status === EntityApprovalNodeStatus.PENDING) {
      return node
    }
  }
  return undefined
}
