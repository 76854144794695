/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Devops API
 * devops server
 * OpenAPI spec version: 1.0
 */

export type CloudconfigTemplateChangePhase = typeof CloudconfigTemplateChangePhase[keyof typeof CloudconfigTemplateChangePhase];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CloudconfigTemplateChangePhase = {
  in_progress: 'in_progress',
  success: 'success',
  terminated: 'terminated',
  terminated_and_rollback: 'terminated_and_rollback',
} as const;
