import { Card, Tabs, Typography, Col, Row, Divider, Button } from 'antd'
import React, { useEffect, useState } from 'react'

import {
  FormsBindedSupportPlan,
  FormsSupportPlan,
  FormsSupportPlanInput
} from 'apiClient/api/interface'
import {
  getPublicSupportPlan,
  updatePublicSupportPlan
} from 'apiClient/services/billing'
import {
  convertMonthlyUsage,
  formatMonthlyUsage,
  formatSupportPlanType,
  PlanLevels,
  SuopportPlanForm,
  SupportPlanCostType
} from 'pages/Tenants/TenantDetail/BusinessInfo/SupportPlan'

import { ARTable } from './CRTable'
import { GlobalDiscount } from './GlobalDiscount'
import { RiskLevelPanel } from './RiskLevel'

const { TabPane } = Tabs

export const SupportPlanPanel: React.FC<{}> = () => {
  const [activeTab, setActiveTab] = useState<string>(PlanLevels.BASIC)

  const [plans, setPlans] = useState<FormsSupportPlan[]>([])

  const plansMap: Record<string, FormsBindedSupportPlan> = plans.reduce(
    (prev, next) => {
      prev[next.level || ''] = next
      return prev
    },
    {
      [PlanLevels.BASIC]: {
        level: PlanLevels.BASIC,
        type: SupportPlanCostType.FIXED_PRICE
      }
    }
  )

  const fetchPlans = () => {
    getPublicSupportPlan()
      .then((resp) => {
        setPlans(resp.data)
      })
      .catch((e) => {})
  }

  const items = [
    {
      key: 'type',
      label: 'Type',
      formatter: (value: string) => {
        return formatSupportPlanType(value)
      }
    },
    {
      key: 'fixed_charge_price',
      label: 'Monthly Costs',
      formatter: (value: string | number | undefined) => {
        if (value === undefined) {
          return '-'
        }
        return `$${value}`
      }
    },
    plansMap[activeTab]?.type === SupportPlanCostType.FIXED_PRICE
      ? null
      : {
          key: 'support_price_percentage',
          label: 'Percentage of monthly usage',
          formatter: (value: string | undefined) => {
            if (value === undefined) {
              return '-'
            }
            return `${formatMonthlyUsage(value)}%`
          }
        }
  ]

  const onSubmit = async (data: FormsSupportPlanInput) => {
    data.support_price_percentage = convertMonthlyUsage(
      data.support_price_percentage || ''
    ).toString()
    await updatePublicSupportPlan(
      plansMap[activeTab].support_plan_id?.toString() || '',
      {
        ...plansMap[activeTab],
        ...data
      }
    )
    fetchPlans()
  }

  useEffect(() => {
    fetchPlans()
  }, [])

  return (
    <div>
      <Card style={{ width: '45%' }}>
        <Tabs
          defaultActiveKey={PlanLevels.BASIC}
          onChange={(tab) => setActiveTab(tab)}
        >
          {[
            PlanLevels.BASIC,
            PlanLevels.STANDARD,
            PlanLevels.ENTERPRISE,
            PlanLevels.PREMIUM
          ].map((pl) => {
            const currntPlan = plansMap[activeTab] || {}
            return (
              <TabPane tab={pl} key={pl}>
                {items.map((item) => {
                  if (!item) {
                    return null
                  }
                  return (
                    <Row key={item.key}>
                      <Col flex="auto">
                        <Typography.Text>{item.label}</Typography.Text>
                      </Col>
                      <Col flex="none">
                        <Typography.Text>
                          {item.formatter
                            ? item.formatter(currntPlan[item.key])
                            : currntPlan[item.key]}
                        </Typography.Text>
                      </Col>
                      <Divider />
                    </Row>
                  )
                })}
                {pl !== PlanLevels.BASIC && (
                  <Row>
                    <Col flex="auto"></Col>
                    <Col flex="none">
                      <SuopportPlanForm
                        onSubmit={onSubmit}
                        withDate={false}
                        trigger={<Button type="primary">Edit</Button>}
                        disabeld={true}
                        initValues={{
                          ...plansMap[activeTab],
                          support_price_percentage: plansMap[activeTab]
                            ?.support_price_percentage
                            ? formatMonthlyUsage(
                                plansMap[activeTab].support_price_percentage ||
                                  '0'
                              ).toString()
                            : '0'
                        }}
                      ></SuopportPlanForm>
                    </Col>
                  </Row>
                )}
              </TabPane>
            )
          })}
        </Tabs>
      </Card>
    </div>
  )
}

enum SettingTabs {
  SUPPORT_PLAN = 'Support Plan',
  RISK_LEVEL = 'Risk Level',
  DISCOUNTS = 'Discounts',
  AR_TABLE = 'AR Table'
}

export const SettingsPanel = () => {
  return (
    <Tabs defaultActiveKey={SettingTabs.SUPPORT_PLAN}>
      <TabPane tab={SettingTabs.SUPPORT_PLAN} key={SettingTabs.SUPPORT_PLAN}>
        <SupportPlanPanel />
      </TabPane>
      <TabPane tab={SettingTabs.RISK_LEVEL} key={SettingTabs.RISK_LEVEL}>
        <RiskLevelPanel />
      </TabPane>
      <TabPane tab={SettingTabs.DISCOUNTS} key={SettingTabs.DISCOUNTS}>
        <GlobalDiscount />
      </TabPane>
      <TabPane tab={SettingTabs.AR_TABLE} key={SettingTabs.AR_TABLE}>
        <ARTable />
      </TabPane>
    </Tabs>
  )
}
