import {
  Alert,
  Button,
  Card,
  Descriptions,
  Modal,
  Select,
  Space,
  Table
} from 'antd'
import Title from 'antd/lib/typography/Title'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  cloudConfigControllerDiffTemplateChangeRelease,
  cloudConfigControllerListConfigTypes,
  cloudConfigControllerReleaseTemplateChange
} from 'apiClient/services/devops'
import {
  CloudconfigDiffTemplateChangeReleaseResp,
  CloudconfigTemplateChangePhase,
  CloudconfigTemplateChangeRelease
} from 'apiClient/services/devops/interface'
import { toLocal } from 'pages/Devops/Maintenance/Detail'

import {
  IndexedCloudconfigTemplateDefaultCaseBinding,
  columnsTemplate
} from '../DefaultTemplate'

function TemplateChangeRelease({
  release,
  phase
}: {
  release: CloudconfigTemplateChangeRelease
  phase?: CloudconfigTemplateChangePhase
}) {
  const { change_id } = useParams<{ change_id: string }>()
  const [releaseDiff, setReleaseDiff] =
    useState<CloudconfigDiffTemplateChangeReleaseResp>()
  const [reload, setReload] = useState(true)
  useEffect(() => {
    fetch()
  }, [reload, release, phase])
  async function fetch() {
    try {
      const releaseDiffResp = (
        await cloudConfigControllerDiffTemplateChangeRelease(change_id, {
          environment: release.environment!
        })
      ).data
      setReleaseDiff(releaseDiffResp)
    } catch (error) {
      Modal.error({
        title: error.response.data.errcode,
        content: error.response.data.errmsg
      })
    }
  }

  const handleRelease = async () => {
    Modal.info({
      title: 'Info',
      closable: true,
      content: (
        <div>
          <p>
            Are you sure to <b>RELEASE</b>?
          </p>
        </div>
      ),
      okText: 'Confirm',
      async onOk() {
        try {
          await cloudConfigControllerReleaseTemplateChange(change_id, {
            environment: release.environment!
          })
          Modal.success({
            title: 'info',
            content: (
              <div>
                <p>Successfully Release to {release.environment}</p>
              </div>
            ),
            onOk() {}
          })
          setReload((pre) => !pre)
        } catch (error) {
          Modal.error({
            title: error.response.data.errcode,
            content: error.response.data.errmsg
          })
          setReload((pre) => !pre)
        }
      }
    })
  }

  return (
    <div>
      <Card
        title={<Title level={4}>{release.environment} Release</Title>}
        bordered={false}
        extra={
          <Space>
            <Button
              type="primary"
              onClick={handleRelease}
              disabled={
                !(
                  phase === CloudconfigTemplateChangePhase.in_progress &&
                  releaseDiff?.different === true
                )
              }
            >
              Release to {release.environment}
            </Button>
          </Space>
        }
      >
        <Descriptions title="Information">
          <Descriptions.Item label="Environment" span={1}>
            {release.environment}
          </Descriptions.Item>
          <Descriptions.Item label="Release Generation" span={1}>
            {release.released_generation}
          </Descriptions.Item>
          <Descriptions.Item label="Release At" span={1}>
            {toLocal(release.released_at)}
          </Descriptions.Item>
        </Descriptions>
        {releaseDiff?.different === true ? (
          <Alert
            style={{ marginBottom: '5px' }}
            message={
              'The following default templates has not yet been released in ' +
              release.environment
            }
            type="warning"
            showIcon
          />
        ) : (
          <Alert
            style={{ marginBottom: '5px' }}
            message={
              'All the default templates has been released to the ' +
              release.environment
            }
            type="info"
            showIcon
          />
        )}
        <h3>Released Default Templates</h3>
        <Table<IndexedCloudconfigTemplateDefaultCaseBinding>
          columns={columnsTemplate}
          dataSource={release.released_default_case_bindings?.map((v) => {
            let arr = []
            arr.push(v.template_id)
            arr.push(v.default_case?.version)
            arr.push(v.default_case?.cloud_provider)
            arr.push(v.default_case?.instance_type)
            arr.push(v.default_case?.config_type)
            arr.push(v.cluster_type)
            return { ...v, key: arr.join(', ') }
          })}
          size="small"
        />
        <br></br>
        <h3>Unreleased Default Templates</h3>
        <Table<IndexedCloudconfigTemplateDefaultCaseBinding>
          columns={columnsTemplate}
          dataSource={releaseDiff?.unreleased_bindings?.map((v1) => {
            let v = v1.origin_binding!
            v.template_id = v1.to_template_id
            let arr = []
            arr.push(v.template_id)
            arr.push(v.default_case?.version)
            arr.push(v.default_case?.cloud_provider)
            arr.push(v.default_case?.instance_type)
            arr.push(v.default_case?.config_type)
            arr.push(v.cluster_type)
            return { ...v, key: arr.join(', ') }
          })}
          size="small"
        />
      </Card>
    </div>
  )
}
export default TemplateChangeRelease
