import {
  ExportOutlined,
  FolderOutlined,
  UserOutlined,
  MailOutlined,
  TeamOutlined
} from '@ant-design/icons'
import { Button, Card, Divider, Layout, Menu, Modal, Space } from 'antd'
import MenuItem from 'antd/lib/menu/MenuItem'
import SubMenu from 'antd/lib/menu/SubMenu'
import Title from 'antd/lib/typography/Title'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  cloudConfigControllerCloseTemplateChange,
  cloudConfigControllerGetTemplateChange
} from 'apiClient/services/devops'
import {
  CloudconfigTemplateChange,
  CloudconfigTemplateChangePhase,
  CloudconfigTemplateChangeRelease
} from 'apiClient/services/devops/interface'
import ModalForm from 'components/ModalForm'

import CloseTemplateForm, { CloseTemplateType } from './Form/CloseTemplateForm'
import TemplateChangeModify from './TemplateChangeModify'
import TemplateChangeOverview from './TemplateChangeOverview'
import TemplateChangeRelease from './TemplateChangeRelease'

const { Header, Footer, Sider, Content } = Layout

function TemplateChange() {
  const { change_id } = useParams<{ change_id: string }>()
  const [change, setChange] = useState<CloudconfigTemplateChange>()
  const [selected, setSelected] = useState('overview')
  const [modalClose, setModalClose] = useState(false)
  const [reload, setReload] = useState(true)
  useEffect(() => {
    fetch()
  }, [reload])
  async function fetch() {
    try {
      const chg = (await cloudConfigControllerGetTemplateChange(change_id)).data
        .change
      setChange(chg)
    } catch (error) {
      Modal.error({
        title: error.response.data.errcode,
        content: error.response.data.errmsg
      })
    }
  }
  const dict = {
    overview: <TemplateChangeOverview change={change}></TemplateChangeOverview>,
    modify: (
      <TemplateChangeModify
        scope={change?.scope!}
        setReload={setReload}
      ></TemplateChangeModify>
    )
  }
  const filterReleaseByName = (
    releases: CloudconfigTemplateChangeRelease[],
    name: string
  ) => {
    for (const release of releases) {
      if (name.includes(release.environment!)) {
        return release
      }
    }
  }

  const renderFormFields = useCallback(
    (form) => {
      return <CloseTemplateForm form={form} />
    },
    [modalClose]
  )

  const handleClose = async (payload: CloseTemplateType) => {
    Modal.info({
      title: 'Info',
      closable: true,
      content: (
        <div>
          <p>
            Are you sure to <b>Close</b> this template change?
          </p>
        </div>
      ),
      okText: 'Confirm',
      async onOk() {
        try {
          await cloudConfigControllerCloseTemplateChange(change_id, payload)
          Modal.success({
            title: 'info',
            content: (
              <div>
                <p>Successfully Close</p>
              </div>
            ),
            onOk() {}
          })
          setModalClose(false)
          setReload((pre) => !pre)
        } catch (error) {
          Modal.error({
            title: error.response.data.errcode,
            content: error.response.data.errmsg
          })
          setModalClose(false)
          setReload((pre) => !pre)
        }
      }
    })
  }

  return (
    <div>
      <Card
        title={<Title level={4}>Modify Default Templates</Title>}
        bordered={false}
        extra={
          <Space>
            <Button
              type="primary"
              onClick={() => {
                setModalClose(true)
              }}
              disabled={
                !(change?.phase === CloudconfigTemplateChangePhase.in_progress)
              }
            >
              Close
            </Button>
          </Space>
        }
      >
        <Layout>
          <Sider theme="light">
            <Menu
              inlineCollapsed={false}
              defaultOpenKeys={['jobs']}
              mode="inline"
              defaultSelectedKeys={['overview']}
              onSelect={(e) => {
                setSelected(e.key)
                setReload((pre) => !pre)
              }}
            >
              <Menu.Item icon={<ExportOutlined />} key={'overview'}>
                Overview
              </Menu.Item>
              <Divider></Divider>
              <SubMenu key={'jobs'} icon={<FolderOutlined />} title={'Jobs'}>
                <Menu.Item key="modify">Modify Templates</Menu.Item>
                {change?.releases?.map((release) => (
                  <Menu.Item key={release.environment + ' release'}>
                    {release.environment + ' Release'}
                  </Menu.Item>
                ))}
              </SubMenu>
            </Menu>
          </Sider>
          <Layout>
            <Content className="app-content">
              {selected.includes('release') ? (
                <TemplateChangeRelease
                  release={filterReleaseByName(change?.releases!, selected)!}
                  phase={change?.phase}
                ></TemplateChangeRelease>
              ) : (
                dict[selected]
              )}
            </Content>
          </Layout>
        </Layout>
      </Card>

      <ModalForm<CloseTemplateType>
        visible={!!modalClose}
        name="close"
        title={'Close Template Modification'}
        getFields={renderFormFields}
        onSubmit={handleClose}
        onCancel={() => {
          setModalClose(false)
        }}
      />
    </div>
  )
}

export default TemplateChange
