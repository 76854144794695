import {
  Button,
  Descriptions,
  Divider,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Select
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { useEffect, useState } from 'react'

import {
  cloudConfigControllerGetParameterAttribute,
  cloudConfigControllerListConfigTypes,
  cloudConfigControllerListParameterAttributes
} from 'apiClient/services/devops'
import {
  CloudconfigConfigurationFiles,
  CloudconfigParameterApplyPolicy,
  CloudconfigParameterAttribute
} from 'apiClient/services/devops/interface'
import DebounceSelect from 'components/DebounceSelect/DebounceSelect'

import { getActualValue, parseFormValue } from '../CurrentConfig/Current'

import { convertToString } from './PreviewForm'

export type EditParamType = {
  configType: string
  filename: string
  name: { value: string; label: string }
  type: string
  newVal?: unknown
  inputType: string
  defaultVal: unknown
  applyPolicy: CloudconfigParameterApplyPolicy
}
interface LabelValue {
  label: string
  value: string
}

var dict: CloudconfigParameterAttribute[]

const fixedFiles: string[] = ['tidb', 'tikv', 'tiflash', 'pd']

const EditParamForm = ({
  filename,
  name,
  version,
  form,
  files,
  actionType
}: {
  version?: string
  form: FormInstance
  filename?: string
  name?: { value: string; label: string }
  files?: CloudconfigConfigurationFiles
  actionType?: string
}) => {
  const [opts, setOpts] = useState<string[]>()
  // const [filename, setFilename] = useState<string | undefined>(fn)
  const [type, setType] = useState<string>()
  const [edit, setEdit] = useState(false)
  useEffect(() => {
    const getInitialOpts = async () => {
      try {
        const resp = (
          await cloudConfigControllerListConfigTypes('DEDICATED_TIDB')
        ).data.config_types
        setOpts(resp)
      } catch (e) {
        console.error(e)
      }
    }
    const getInitialAttribute = async () => {
      await handleConfigTypeChange(filename || '')
      await handleAttributeChange({ label: name?.value!, value: name?.value! })
    }

    getInitialAttribute()
    if (name === undefined) {
      getInitialOpts()
    }
  }, [])

  async function fetchParam(like: string): Promise<LabelValue[]> {
    return cloudConfigControllerListParameterAttributes(
      form.getFieldValue(['configType']),
      version!,
      { name_like: like }
    )
      .then((val) => {
        return val.data.items
      })
      .then((data) => {
        return (
          data && data.length > 0
            ? data
            : form.getFieldValue(['configType']) &&
              fixedFiles.includes(form.getFieldValue(['configType']))
            ? []
            : [{ name: like }]
        ).map((val) => {
          return { label: val.name || '', value: val.name || '' }
        })
      })
  }

  //var dict:CloudconfigParameterAttribute[] = []
  const handleConfigTypeChange = async (val: string) => {
    form.setFieldsValue({ configType: val })
    form.setFieldsValue({ filename: val })
    /*form.setFieldsValue({
      name: null,
      type: null,
      applyPolicy: null,
      defaultVal: null
    })*/

    try {
      dict =
        (
          await cloudConfigControllerListParameterAttributes(
            //form.getFieldValue(['configType']),
            val,
            version!
          )
        ).data.items || []
    } catch (e) {}
  }

  async function searchParam(like: string): Promise<LabelValue[]> {
    if (dict.length > 0) {
      return dict
        .filter((item) => item.name?.startsWith(like))
        .map((val) => {
          return { label: val.name || '', value: val.name || '' }
        })
    } else if (
      form.getFieldValue(['configType']) &&
      fixedFiles.includes(form.getFieldValue(['configType']))
    ) {
      return []
    } else {
      return [{ label: like, value: like }]
    }
  }

  const handleAttributeChange = async (val: LabelValue) => {
    try {
      /*
      const data = (
        await cloudConfigControllerGetParameterAttribute(
          form.getFieldValue(['configType']),
          version!,
          val.value
        )
      ).data.attribute
      */
      const data = dict.find((item) => item.name == val.value) || {}
      form.setFieldsValue({ defaultVal: convertToString(data?.default_value) })
      form.setFieldsValue({ type: data?.type })
      form.setFieldsValue({ inputType: data?.type })
      form.setFieldsValue({ applyPolicy: data?.apply_policy })
      const actVal = getActualValue(
        files!,
        form.getFieldValue(['configType']),
        //form.getFieldValue(['name']).value
        val.value
      )
      if (actVal === null) {
        form.setFieldsValue({ current: convertToString(data?.default_value) })
      } else {
        form.setFieldsValue({ current: convertToString(actVal) })
      }
    } catch (e) {
      const actVal = getActualValue(
        files!,
        form.getFieldValue(['configType']),
        form.getFieldValue(['name']).value
      )
      if (actVal !== null) {
        form.setFieldsValue({ current: convertToString(actVal) })
      }
      form.setFieldsValue({ defaultVal: 'unknown' })
      form.setFieldsValue({ type: 'unknown' })
    }
  }

  function validateValue() {
    let validator: any[]
    switch (form.getFieldValue(['inputType'])) {
      case 'int':
        validator = [
          {
            validator: async (_: any, value: any) => {
              if (!IsInt(value)) {
                return Promise.reject(new Error('the input should be an int'))
              }
            }
          }
        ]
        break
      case 'float':
        validator = [
          {
            validator: async (_: any, value: any) => {
              if (!IsFloat(value)) {
                return Promise.reject(new Error('the input should be float'))
              }
            }
          }
        ]
        break
      case 'bool':
        validator = [
          {
            validator: async (_: any, value: any) => {
              if (!IsBool(value)) {
                return Promise.reject(new Error('the input should be bool'))
              }
            }
          }
        ]
        break
      case 'json':
        validator = [
          {
            validator: async (_: any, value: any) => {
              if (!IsJson(value)) {
                return Promise.reject(
                  new Error('the input should be json format')
                )
              }
            }
          }
        ]
        break
      default:
        validator = []
    }
    validator.push({
      required: true,
      message: 'new ' + form.getFieldValue(['inputType']) + ' value is required'
    })
    validator.push({
      validator: async (_: any, valueStr: any) => {
        var dictItem = dict.find(
          (item) => item.name == form.getFieldValue(['name']).value
        )
        if (dictItem && dictItem.validation && dictItem.validation.length > 0) {
          var value = parseFormValue(dictItem.type, valueStr)
          var validationSchema = JSON.parse(dictItem.validation || '')
          if (validationSchema.enum) {
            if (!(validationSchema.enum as Array<any>).includes(value))
              return Promise.reject(
                new Error(
                  'the intput should be one of ' + validationSchema.enum
                )
              )
          }
          if (validationSchema.maximum) {
            if (value <= validationSchema.Maximum) {
              return Promise.reject(
                new Error(
                  'the maximum number of intput should be ' +
                    validationSchema.maximum
                )
              )
            }
          }
          if (validationSchema.minimum) {
            if (value >= validationSchema.Maximum) {
              return Promise.reject(
                new Error(
                  'the minimum number of intput should be ' +
                    validationSchema.minimum
                )
              )
            }
          }
          if (validationSchema.pattern) {
            if (value.search(validationSchema.pattern)) {
              return Promise.reject(
                new Error(
                  'the intput pattern should be ' + validationSchema.pattern
                )
              )
            }
          }
        }
      }
    })
    return validator
  }

  return (
    <>
      <Form.Item name="configType" label="Config Type">
        <Select
          style={{ width: '100%' }}
          options={opts?.map((v) => {
            return { label: v, value: v }
          })}
          onChange={handleConfigTypeChange}
          disabled={name !== undefined}
        ></Select>
      </Form.Item>
      <Form.Item name="filename" label="Filename">
        <Input
          type="text"
          disabled={true}
          bordered={false}
          style={{ color: 'black' }}
        ></Input>
      </Form.Item>
      <Form.Item name="name" label="Name" required={true}>
        <DebounceSelect
          style={{ width: '100%' }}
          showSearch
          placeholder="Please type to search param's name, and should choose filename first"
          fetchOptions={searchParam}
          onChange={handleAttributeChange}
          disabled={name !== undefined}
        />
      </Form.Item>
      <Form.Item name="type" label="Type">
        <Input
          type="text"
          disabled={true}
          bordered={false}
          style={{ color: 'black' }}
        ></Input>
      </Form.Item>
      <Form.Item name="applyPolicy" label="Apply Policy">
        <Input
          type="text"
          disabled={true}
          bordered={false}
          style={{ color: 'black' }}
        ></Input>
      </Form.Item>
      <Form.Item name={'defaultVal'} label="Default Value">
        <TextArea
          disabled={true}
          bordered={false}
          autoSize
          style={{ color: 'black' }}
        ></TextArea>
      </Form.Item>
      {actionType === undefined ? (
        <Form.Item name={'current'} label="Current Value">
          <TextArea
            disabled={true}
            bordered={false}
            autoSize
            style={{ color: 'black' }}
          ></TextArea>
        </Form.Item>
      ) : (
        <></>
      )}
      {actionType === 'unset' ? (
        <></>
      ) : (
        <>
          <Divider></Divider>
          <div
            className="flex-container"
            style={{ justifyContent: 'flex-end', display: 'flex' }}
          >
            <Button type="primary" onClick={() => setEdit((pre) => !pre)}>
              Edit
            </Button>
          </div>
          <Form.Item
            label="Input Type"
            name="inputType"
            hidden={
              !(
                !!edit &&
                (form.getFieldValue('type') === 'unknown' ||
                  form.getFieldValue('type') === undefined)
              )
            }
            rules={[{ required: true }]}
          >
            <Select
              style={{ width: '100%' }}
              options={[
                { label: 'int', value: 'int' },
                { label: 'float', value: 'float' },
                { label: 'string', value: 'string' },
                { label: 'bool', value: 'bool' },
                { label: 'json', value: 'json' }
              ]}
              onChange={(v) => {
                form.setFieldsValue({ inputType: v })
                setType(String(v))
              }}
            ></Select>
          </Form.Item>
          {!!edit ? (
            <Form.Item label="New Value" name="newVal" rules={validateValue()}>
              <TextArea
                style={{ width: '100%' }}
                autoSize
                placeholder="DO NOT support edit LONG"
              ></TextArea>
            </Form.Item>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  )
}

export function IsFloat(value: string): boolean {
  return /^-?\d+(\.\d+)?$/.test(value)
}

export function IsInt(str: string): boolean {
  const num = parseFloat(str)
  return !isNaN(num) && Number.isInteger(num)
}

export function IsBool(value: string): boolean {
  if (value === 'true' || value === 'false') {
    return true
  } else {
    return false
  }
}

export function IsJson(str: string): boolean {
  try {
    JSON.parse(str)
    return true
  } catch (e) {
    return false
  }
}

export default EditParamForm
