import { Form, Input, Select } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import { useEffect, useState } from 'react'

import { CloudconfigTemplateChangePhase } from 'apiClient/services/devops/interface'

export type CloseTemplateType = {
  result: CloudconfigTemplateChangePhase
  reason?: string
}

function CloseTemplateForm({ form }: { form: FormInstance }) {
  const [result, setResult] = useState<string>()

  useEffect(() => {
    setResult(form.getFieldValue('result'))
  }, [form])

  return (
    <>
      <Form.Item name="result" label="Result" rules={[{ required: true }]}>
        <Select
          options={[
            {
              value: CloudconfigTemplateChangePhase.success,
              label: CloudconfigTemplateChangePhase.success
            },
            {
              value: CloudconfigTemplateChangePhase.terminated,
              label: CloudconfigTemplateChangePhase.terminated
            }
          ]}
          onChange={(e) => {
            setResult(String(e))
            form.setFieldsValue({ result: String(e) })
          }}
        ></Select>
      </Form.Item>
      {result === CloudconfigTemplateChangePhase.terminated ? (
        <Form.Item name="reason" label="Reason" rules={[{ required: true }]}>
          <Input></Input>
        </Form.Item>
      ) : (
        <></>
      )}
    </>
  )
}

export default CloseTemplateForm
