import { Alert, Button, Card, Modal, Select, Space, Table } from 'antd'
import Title from 'antd/lib/typography/Title'
import { Key, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  cloudConfigControllerGetTemplate,
  cloudConfigControllerListConfigTypes,
  cloudConfigControllerUpdateTemplateChange
} from 'apiClient/services/devops'
import {
  CloudConfigControllerGetTemplateClusterType,
  CloudconfigNamedParameter,
  CloudconfigTemplateChangeScope,
  CloudconfigUpdateTemplateChangeReq
} from 'apiClient/services/devops/interface'
import { parseFormValue } from 'pages/Tenants/ClusterDetail/Configs/CurrentConfig/Current'

import {
  IndexedCloudconfigTemplateDefaultCaseBinding,
  columnsTemplate
} from '../DefaultTemplate'

import ExpandTemplateTable from './ExpandTemplateTable'
import BatchModifyForm, { SetEditParamType } from './Form/EditTemplateForm'

function TemplateChangeModify({
  scope,
  setReload
}: {
  scope: CloudconfigTemplateChangeScope
  setReload: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const [configType, setConfigType] = useState(
    scope.default_case_bindings![0].origin_binding?.default_case?.config_type
  )
  const { change_id } = useParams<{ change_id: string }>()
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [modalChange, setModalChange] = useState(false)
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const [params, setParams] = useState<SetEditParamType[]>([])

  const handleCreateTemplateChange = async () => {
    try {
      let req: CloudconfigUpdateTemplateChangeReq = {
        batch_update_template: {
          default_case_bindings: scope.default_case_bindings
            ?.filter((v1) => {
              let v = v1.origin_binding!
              v.template_id = v1.to_template_id
              let arr = []
              arr.push(v1.to_template_id)
              arr.push(v.default_case?.version)
              arr.push(v.default_case?.cloud_provider)
              arr.push(v.default_case?.instance_type)
              arr.push(v.default_case?.config_type)
              arr.push(v.cluster_type)
              return selectedRowKeys.includes(arr.join(', '))
            })
            .map((v) => v.origin_binding!),
          params_to_set: params
            .filter((v) => {
              return v.actionType === 'set'
            })
            .map((v) => {
              return {
                value: parseFormValue(v.type, String(v.newVal)),
                name: v.name.value
              }
            }),
          params_to_unset: params
            .filter((v) => {
              return v.actionType === 'unset'
            })
            .map((v) => {
              return v.name.value
            })
        }
      }
      const resp = (
        await cloudConfigControllerUpdateTemplateChange(change_id, req)
      ).data
      Modal.success({
        title: 'Info',
        content: (
          <div>
            <p>Batch Modify Params Successfully.</p>
          </div>
        ),
        onOk() {}
      })
    } catch (error) {
      Modal.error({
        title: error.response.data.errcode,
        content: error.response.data.errmsg
      })
    } finally {
      setSelectedRowKeys([])
      setReload((pre) => !pre)
      setModalChange(false)
    }
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }

  const handleCancelChange = () => {
    setModalChange(false)
  }

  // expand table related
  const [expandedRowKeys, setExpandedRowKeys] = useState<Key[]>([])
  const [expandedData, setExpandedData] = useState({})

  const onExpand = async (
    expanded: boolean,
    record: IndexedCloudconfigTemplateDefaultCaseBinding
  ) => {
    if (expanded) {
      const params = (
        await cloudConfigControllerGetTemplate(String(record.template_id), {
          cluster_type:
            CloudConfigControllerGetTemplateClusterType.DEDICATED_TIDB
        })
      ).data.template?.parameters
      let arr: CloudconfigNamedParameter[] = []
      for (let key in params) {
        arr.push({ ...params[key] })
      }
      setExpandedData((prev) => ({ ...prev, [record.key]: arr }))
    }
  }

  useEffect(() => {
    // 当 expandedRowKeys 改变时，获取新的数据
    expandedRowKeys.forEach(async (key) => {
      if (!expandedData[key]) {
        const record = scope.default_case_bindings
          ?.map((v1) => {
            let v = v1.origin_binding!
            v.template_id = v1.to_template_id
            let arr = []
            arr.push(v1.to_template_id)
            arr.push(v.default_case?.version)
            arr.push(v.default_case?.cloud_provider)
            arr.push(v.default_case?.instance_type)
            arr.push(v.default_case?.config_type)
            arr.push(v.cluster_type)
            return {
              ...v,
              key: arr.join(', '),
              template_id: v1.to_template_id
            }
          })
          .filter((v) => {
            return v.key === key
          })
        await onExpand(true, record![0])
      }
    })
  }, [expandedRowKeys])

  return (
    <div>
      <Card
        title={<Title level={4}>Templates</Title>}
        bordered={false}
        extra={
          <Space>
            <Button
              type="primary"
              onClick={() => {
                if (selectedRowKeys.length !== 0) {
                  setModalChange(true)
                }
              }}
            >
              Modify
            </Button>
          </Space>
        }
      >
        <Alert
          style={{ marginBottom: '5px' }}
          message={
            'The following default templates are staged and can be modified as needed. All modifications will not be directly released until the "Release Job" is executed.'
          }
          type="info"
          showIcon
        />
        <Select
          options={Array.from(
            new Set(
              scope.default_case_bindings?.map((v) => {
                return v.origin_binding?.default_case?.config_type
              })
            )
          ).map((v) => {
            return { label: v!, value: v! }
          })}
          value={configType}
          onChange={(e) => {
            setConfigType(e)
            setSelectedRowKeys([])
          }}
        ></Select>
        <Table<IndexedCloudconfigTemplateDefaultCaseBinding>
          expandable={{
            expandedRowRender: (record) => (
              <ExpandTemplateTable
                params={expandedData[record.key]}
              ></ExpandTemplateTable>
            ),
            onExpand,
            expandedRowKeys,
            onExpandedRowsChange: (expandedKeys) => {
              setExpandedRowKeys(expandedKeys.slice())
            }
          }}
          rowSelection={rowSelection}
          columns={columnsTemplate}
          dataSource={scope.default_case_bindings
            ?.map((v1) => {
              let v = v1.origin_binding!
              v.template_id = v1.to_template_id
              let arr = []
              arr.push(v1.to_template_id)
              arr.push(v.default_case?.version)
              arr.push(v.default_case?.cloud_provider)
              arr.push(v.default_case?.instance_type)
              arr.push(v.default_case?.config_type)
              arr.push(v.cluster_type)
              return {
                ...v,
                key: arr.join(', '),
                template_id: v1.to_template_id
              }
            })
            .filter((v) => {
              return v.default_case?.config_type === configType
            })}
          size="small"
        />
      </Card>
      <Modal
        title={'Batch Modify Templates'}
        visible={!!modalChange}
        onCancel={handleCancelChange}
        width={1000}
        destroyOnClose={true}
        okText={'Confirm'}
        onOk={handleCreateTemplateChange}
      >
        <BatchModifyForm
          bindings={scope.default_case_bindings
            ?.map((v1) => {
              let v = v1.origin_binding!
              v.template_id = v1.to_template_id
              let arr = []
              arr.push(v1.to_template_id)
              arr.push(v.default_case?.version)
              arr.push(v.default_case?.cloud_provider)
              arr.push(v.default_case?.instance_type)
              arr.push(v.default_case?.config_type)
              arr.push(v.cluster_type)
              return { ...v, key: arr.join(', ') }
            })
            .filter((v) => {
              return selectedRowKeys.includes(v.key)
            })}
          params={params}
          setParams={setParams}
        ></BatchModifyForm>
      </Modal>
    </div>
  )
}
export default TemplateChangeModify
