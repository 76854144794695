import { Button, Divider, FormInstance, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { useCallback, useMemo, useState } from 'react'

import ModalForm from 'components/ModalForm'
import EditParamForm, {
  EditParamType
} from 'pages/Tenants/ClusterDetail/Configs/Forms/EditParamForm'

import {
  IndexedCloudconfigTemplateDefaultCaseBinding,
  columnsTemplate
} from '../../DefaultTemplate'

export type SetEditParamType = EditParamType & { actionType: string }

function BatchModifyForm({
  bindings,
  params,
  setParams
}: {
  bindings?: IndexedCloudconfigTemplateDefaultCaseBinding[]
  params: SetEditParamType[]
  setParams: React.Dispatch<React.SetStateAction<SetEditParamType[]>>
}) {
  const [modalChange, setModalChange] = useState(false)

  const [modalUnset, setModalUnset] = useState(false)

  const renderEditParamFields = useCallback((form: FormInstance) => {
    form.setFieldsValue({
      configType: bindings![0].default_case?.config_type,
      filename: bindings![0].default_case?.config_type
    })
    return (
      <EditParamForm
        form={form}
        version={bindings![0].default_case?.version}
        actionType="set"
        filename={bindings![0].default_case?.config_type}
      />
    )
  }, [])

  const renderUnsetParamFields = useCallback((form: FormInstance) => {
    form.setFieldsValue({
      configType: bindings![0].default_case?.config_type,
      filename: bindings![0].default_case?.config_type
    })
    return (
      <EditParamForm
        form={form}
        version={bindings![0].default_case?.version}
        actionType="unset"
        filename={bindings![0].default_case?.config_type}
      />
    )
  }, [])

  const handleEdit = (payload: EditParamType) => {
    setParams((prevParams) => {
      const newParams = prevParams.filter((item) => {
        return (
          item.configType != payload.configType ||
          item.name.value != payload.name.value
        )
      })
      return [...newParams, { ...payload, actionType: 'set' }]
    })

    setModalChange(false)
  }

  const handleUnset = (payload: EditParamType) => {
    const newParams = params.filter((item) => {
      return (
        item.configType != payload.configType ||
        item.name.value != payload.name.value
      )
    })
    setParams([...newParams, { ...payload, actionType: 'unset' }])
    setModalUnset(false)
  }

  const handleCancelEdit = useCallback(() => {
    setModalChange(false)
  }, [])

  const handleCancelUnset = useCallback(() => {
    setModalUnset(false)
  }, [])

  const columns: ColumnsType<SetEditParamType> = useMemo(
    () => [
      {
        title: 'Operation',
        render: (_: any, record) => {
          return record.actionType
        }
      },
      {
        title: 'Config Type',
        render: (_: any, record) => {
          return record.configType
        }
      },
      {
        title: 'Param Name',
        render: (_: any, record) => {
          return record.name.value
        }
      },
      {
        title: 'Value',
        render: (_: any, record) => {
          return record.newVal
        }
      },
      {
        title: 'Action',
        render: (_: any, record) => {
          return (
            <div>
              <Button
                type="link"
                onClick={() => {
                  setParams((prevParams) => {
                    const newParams = prevParams.filter((item) => {
                      return (
                        item.configType != record.configType ||
                        item.name.value != record.name.value
                      )
                    })
                    return newParams
                  })
                }}
              >
                Remove
              </Button>
            </div>
          )
        }
      }
    ],
    [params]
  )

  return (
    <div>
      <Table<IndexedCloudconfigTemplateDefaultCaseBinding>
        columns={columnsTemplate}
        dataSource={bindings}
        size="small"
        pagination={false}
      />
      <Divider></Divider>
      <h3>Operations</h3>
      <div
        className="flex-container"
        style={{ justifyContent: 'flex-end', display: 'flex' }}
      >
        <div>
          <Button
            type="primary"
            onClick={() => {
              setModalChange(true)
            }}
          >
            Set Param
          </Button>
          &nbsp;
          <Button
            type="primary"
            onClick={() => {
              setModalUnset(true)
            }}
          >
            Unset Param
          </Button>
        </div>
      </div>
      <Table<EditParamType>
        columns={columns}
        dataSource={params}
        size="small"
        pagination={false}
      ></Table>
      <ModalForm<SetEditParamType>
        visible={!!modalChange}
        name="editParam"
        title={'Set Parameter'}
        getFields={renderEditParamFields}
        onSubmit={handleEdit}
        onCancel={handleCancelEdit}
        width={600}
        destoryOnClose={true}
        okText="OK"
      />
      <ModalForm<SetEditParamType>
        visible={!!modalUnset}
        name="unsetParam"
        title={'Unset Parameter'}
        getFields={renderUnsetParamFields}
        onSubmit={handleUnset}
        onCancel={handleCancelUnset}
        width={600}
        destoryOnClose={true}
        okText="OK"
      />
    </div>
  )
}

export default BatchModifyForm
