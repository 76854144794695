import {
  PieChartOutlined,
  TeamOutlined,
  ClusterOutlined,
  ProfileOutlined,
  HistoryOutlined,
  SettingOutlined,
  SolutionOutlined,
  DeploymentUnitOutlined,
  SafetyCertificateOutlined,
  UnorderedListOutlined,
  MonitorOutlined,
  CodeSandboxOutlined,
  NodeIndexOutlined,
  ShareAltOutlined,
  GoldOutlined,
  FormOutlined,
  NotificationOutlined,
  UserOutlined,
  ScheduleOutlined,
  BarsOutlined,
  AmazonOutlined
} from '@ant-design/icons'
import { Layout, Menu } from 'antd'
import SubMenu from 'antd/lib/menu/SubMenu'
import React, { useState, useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import useProfileContext from 'useProfileContext'

import logoThumbnail from 'images/logo-thumbnail.svg'
import logo from 'images/logo.svg'
import { capitalize } from 'utils/string'

const { Sider } = Layout

interface Item {
  name: string
  title?: string
  icon: JSX.Element
  submenus?: Item[]
}

const adminItems: Item[] = [
  {
    name: 'admin',
    icon: <HistoryOutlined />,
    submenus: [
      {
        name: 'audits',
        icon: <HistoryOutlined />
      },
      {
        name: 'access',
        icon: <TeamOutlined />
      }
    ]
  }
]

const simpleItems: Item[] = [
  {
    name: 'overview',
    icon: <PieChartOutlined />
  },
  {
    name: 'orgs',
    icon: <ClusterOutlined />
  },
  {
    name: 'devops',
    title: 'DevOps',
    icon: <DeploymentUnitOutlined />,
    submenus: [
      {
        name: 'release',
        icon: <UnorderedListOutlined />
      },
      {
        name: 'maintenance',
        icon: <NodeIndexOutlined />
      },
      {
        name: 'configchange',
        icon: <CodeSandboxOutlined />
      },
      {
        name: 'operations',
        icon: <ShareAltOutlined />
      },
      {
        name: 'jumps',
        icon: <ShareAltOutlined />
      },
      {
        name: 'k8sUpgradeTask',
        icon: <SafetyCertificateOutlined />
      }
    ]
  },
  {
    name: 'eventCenter',
    icon: <ProfileOutlined />,
    submenus: [
      {
        name: 'incidents',
        icon: <ProfileOutlined />
      }
    ]
  },
  {
    name: 'monitor',
    title: 'Monitor',
    icon: <MonitorOutlined />,
    submenus: [
      {
        name: 'certification',
        icon: <SafetyCertificateOutlined />
      },
      {
        name: 'infraInformation',
        icon: <SafetyCertificateOutlined />
      }
    ]
  },
  {
    name: 'settings',
    title: 'DBaaS Settings',
    icon: <SettingOutlined />,
    submenus: [
      {
        name: 'business',
        icon: <SettingOutlined />
      },
      {
        name: 'providers',
        icon: <ProfileOutlined />
      },
      {
        name: 'rbac',
        title: 'RBAC',
        icon: <SolutionOutlined />
      }
    ]
  },
  {
    name: 'partner',
    title: 'Partner',
    icon: <TeamOutlined />,
    submenus: [
      {
        name: 'msp',
        title: 'MSP',
        icon: <GoldOutlined />
      },
      {
        name: 'cppo',
        title: 'AWS CPPO Reseller',
        icon: <AmazonOutlined />
      }
    ]
  },
  {
    name: 'configcenter',
    title: 'Config Center',
    icon: <FormOutlined />,
    submenus: [
      {
        name: 'dict',
        icon: <FormOutlined></FormOutlined>
      },
      {
        name: 'template',
        icon: <FormOutlined></FormOutlined>
      }
    ]
  },
  {
    name: 'notification',
    title: 'Notification',
    icon: <NotificationOutlined />
  },
  {
    name: 'userbehavior',
    title: 'User Behavior',
    icon: <UserOutlined />,
    submenus: [
      {
        name: 'userbehavior_subscriptions',
        title: 'User Behavior Subscriptions',
        icon: <ScheduleOutlined />
      }
    ]
  }
]

function getAllowedLinks(roles: string[]) {
  if (roles.indexOf('admin') > -1) {
    return [...simpleItems, ...adminItems]
  }

  return simpleItems
}

export default () => {
  const [collapsed, setCollapsed] = useState(false)
  const { pathname } = useLocation()
  const {
    profile: { roles = [] }
  } = useProfileContext()

  const allowedLinks = useMemo(() => getAllowedLinks(roles), [roles])

  const handleCollapse = (collapsed: boolean) => {
    setCollapsed(collapsed)
  }

  const openedkeys = [pathname.slice(1).split('/')[0] || '']

  return (
    <Sider
      className="app-sidebar"
      breakpoint="md"
      collapsible
      collapsed={collapsed}
      onCollapse={handleCollapse}
    >
      <div className="logo" style={{ marginBottom: '30px' }}>
        <img src={collapsed ? logoThumbnail : logo} alt="TiDB Cloud" />
      </div>

      <Menu theme="dark" selectedKeys={openedkeys} mode="inline">
        {allowedLinks.map((item) => {
          if (item.submenus !== undefined) {
            return (
              <SubMenu
                key={item.name}
                icon={item.icon}
                title={item.title || capitalize(item.name)}
              >
                {item.submenus.map((submenu) => (
                  <Menu.Item key={submenu.name} icon={submenu.icon}>
                    <Link to={`/${submenu.name}`}>
                      {submenu.title || capitalize(submenu.name)}
                    </Link>
                  </Menu.Item>
                ))}
              </SubMenu>
            )
          } else {
            return (
              <Menu.Item key={item.name} icon={item.icon}>
                <Link to={`/${item.name}`}>
                  {item.title || capitalize(item.name)}
                </Link>
              </Menu.Item>
            )
          }
        })}
      </Menu>
    </Sider>
  )
}
